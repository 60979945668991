import React, { useContext, useState } from "react";
import tw, { styled } from "twin.macro";
import { useDispatch } from "react-redux";
import { setRating } from "../../store/actions/feedback";
import { ThemeContext } from "../themes/theme-context";
// import FeedbackIcon from "./feedback-icon";
import FeedbackIcon from "../dynamic-components/feedback-icon";

const StyledButton = styled.button(({ hoverGradient }) => [
	tw`p-1.5 shadow-resource-button rounded-full bg-white`,
	hoverGradient,
]);

const FeedbackRating = ({ data, resourceId, ratingStyles }) => {
	const dispatch = useDispatch();
	const { theme } = useContext(ThemeContext);

	const handleRating = (resourceId, ratingId, title, value) => {
		// console.log(resourceId, ratingId, title, value);
		dispatch(setRating(resourceId, ratingId, title, value));
	};

	const [currentRating, setCurrentRating] = useState(null);

	return (
		<div css={ratingStyles.styledRating}>
			<h4 css={ratingStyles.styledQuestion}>{data.ratingQuestion}</h4>
			<div css={ratingStyles.styledRatingScale}>
				{data.ratingScale.rating.map((item) => {
					// console.log(item);
					return (
						<StyledButton
							hoverGradient={theme.iconHoverGradient}
							onClick={() =>
								handleRating(
									resourceId,
									item.contentful_id,
									item.title,
									item.ratingValue
								)
							}
							onMouseEnter={() => setCurrentRating(item)}
							onMouseLeave={() => setCurrentRating(null)}
							key={item.id}
						>
							<FeedbackIcon theme={theme} item={item} current={currentRating} />
							<span tw="sr-only">{item.title}</span>
						</StyledButton>
					);
				})}
			</div>
		</div>
	);
};

export default FeedbackRating;
