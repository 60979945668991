import loadable from "@loadable/component";
import React from "react";

const FeedbackIconProxy = loadable(() => import("../feedback/feedback-icon"));

const FeedbackIcon = ({ theme, item, current }) => {
	return <FeedbackIconProxy theme={theme} item={item} current={current} />;
};

export default FeedbackIcon;
